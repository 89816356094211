const Travel = () => {
  return (
    <div className="bg-travel h-screen bg-cover bg-center flex items-center justify-center">
      <section className="text-4xl text-shadow shadow-[#727171] flex flex-col items-center">
        <span>Hey there! This page is still a work in progress! 👩🏻‍💻 </span>
        <span>Swing by again later to see the updates! 😊</span>
      </section>
    </div>
  );
};

export default Travel;
