import { useEffect, useRef, useState } from "react";
import about from "../data/about";
import { WORK_STARTING_YEAR } from "../utils/constants";

const Hero = () => {
  const [heroBackgroundAttachment, setHeroBackgroundAttachment] =
    useState("bg-fixed");
  const myRef = useRef(null);
  const { intro, description } = about;
  const heroDescription = description.replace(
    "{{yearsOfExperience}}",
    (new Date().getFullYear() - WORK_STARTING_YEAR).toString()
  );

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (myRef && myRef.current) {
        const rect = (myRef.current as Element).getBoundingClientRect();
        const isInViewport =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth);

        if (isInViewport) {
          setHeroBackgroundAttachment("bg-fixed");
        } else {
          setHeroBackgroundAttachment("bg-unset");
        }
      }
    });
  }, []);

  return (
    <>
      <div className="h-screen bg-greenGradient">
        <section className="absolute right-[20%] bottom-[30%] text-5xl font-extrabold w-1/5 text-gray-50">
          {intro}
        </section>
      </div>
      <div className="overflow-scroll" ref={myRef} />
      <div
        className={`relative bg-hero h-screen bg-cover bg-center ${heroBackgroundAttachment} bg-no-repeat`}
      >
        <span className="absolute right-[20%] bottom-[15%] w-1/4 text-white text-xl font-bold  text-shadow shadow-[#727171] italic">
          {heroDescription}
        </span>
      </div>
    </>
  );
};

export default Hero;
