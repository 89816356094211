import { Link } from "react-router-dom";
import navs from "../data/nav";

const Nav = () => {
  return (
    <div>
      <ul>
        {navs.map((nav) => {
          const { header, link, description } = nav;
          return (
            <li
              key={link}
              className="mt-10 mx-20 py-4 px-8 rounded flex items-center gap-8 justify-center font-bold text-2xl bg-greenGradient shadow-md cursor-pointer hover:scale-105"
            >
              <Link to={link}>
                <h2 className="text-center mb-2">{header}</h2>
                <span className="text-lg">{description}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Nav;
