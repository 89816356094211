import { useEffect, useRef } from "react";
import { projectPage, projects } from "../data/projects";

const Projects = () => {
  const { title, description } = projectPage;
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (headerRef && headerRef.current) {
      (headerRef.current as HTMLDivElement).scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    }
  }, [headerRef]);

  return (
    <>
      <div className="mx-20 my-2 p-6" ref={headerRef}>
        <h1 className="text-2xl text-center text-forest font-semibold mb-4">
          {title}
        </h1>
        <h2 className="text-lg text-center text-forest">{description}</h2>
      </div>
      {projects.map((project) => {
        const {
          id,
          projectName,
          projectLink,
          projectSnapshot,
          description,
          tech,
          sourceCodeLink,
        } = project;
        return (
          <a className="cursor-pointer" href={projectLink} target="_blank">
            <div className="mx-20 my-8 bg-forest p-6 rounded-lg shadow-lg hover:scale-105">
              <div key={id} className="flex justify-between mb-8">
                <div className="w-1/3">
                  <img className="h-60" src={projectSnapshot} />
                </div>
                <ul className="w-1/2">
                  <li className="text-lg font-semibold">{projectName}</li>
                  <br />
                  <li>{description}</li>
                  <br />
                  <li>Tech stack : {tech.join(", ")}</li>
                  <br />
                  <li>
                    <a
                      className="font-bold text-feather underline"
                      href={sourceCodeLink}
                      target="_blank"
                    >
                      See the code
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </a>
        );
      })}
    </>
  );
};

export default Projects;
